import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { buildUrl } from '~/common';
import { RulesTypes } from '~/common/modules/filterRules/types';
import { getIcon } from '~/common/modules/filterRules/utils';
import CardMessage from '~/components/src/CardMessage';
import Icons from '~/components/src/Icons';
import Link from '~/components/src/Link';
import ShowMore from '~/components/src/ShowMore';
import { Table } from '~/components/src/Table/components';
import i18n from '~/i18n';
import { checkDuplicateEventWithDateProperties } from '../RuleCardEdit';
import './styles.scss';

const CRITERIA_DEFAULT_TIME_PERIOD = '30d';

const defaultVisibleProperties = ['property', 'constraint', 'filter', 'unique', 'storedVariable'];

const cellSizeClassNames = {
  2: 'u-size4of8',
  3: 'u-size4of12',
  4: 'u-size2of8',
  5: 'u-size2of10',
};

const TableRowProperty = ({
  property,
  constraint,
  constraintGroup,
  filter,
  unique,
  storedVariable,
  hidden = false,
  visibleColumns = defaultVisibleProperties,
  hasDateRuleConstraint,
}) => {
  const columnClassName = cx('Table-cell', cellSizeClassNames[visibleColumns.length]);
  const hasPropertyError = hasDateRuleConstraint && constraintGroup === 'DATE';

  return (
    <div
      className={cx('Table-row', { 'u-hidden': hidden }, 'RuleCardView-table-row', 't-propertyRow', {
        'RuleCardEdit-table-row--error': hasPropertyError,
      })}
      key={property + constraint + filter + storedVariable}
    >
      {visibleColumns.includes('property') && (
        <div className={cx(columnClassName, 't-propertyTypeName')}>{property}</div>
      )}
      {visibleColumns.includes('constraint') && (
        <div className={cx(columnClassName, 'flex flex-col')}>
          <span>{constraint}</span>
          <span className="text-r42-blue w-fit py-1 text-xs font-medium">{constraintGroup}</span>
        </div>
      )}
      {visibleColumns.includes('filter') && <div className={columnClassName}>{filter}</div>}
      {visibleColumns.includes('unique') && <div className={columnClassName}>{unique}</div>}
      {(visibleColumns.includes('storedVariable') || visibleColumns.includes('configuredVariable')) && (
        <div className={cx(columnClassName, 'flex flex-col')}>
          <span className="t-variableName">{storedVariable?.name}</span>
          <span className="text-r42-blue t-variableType w-fit py-1 text-xs font-medium">{storedVariable?.type}</span>
        </div>
      )}
    </div>
  );
};

const getTimeCondition = (timeCondition = CRITERIA_DEFAULT_TIME_PERIOD) => {
  if (timeCondition === null) {
    return null;
  }

  const numberValue = parseInt(timeCondition, 10);
  if (timeCondition.includes('d')) return `${numberValue} ${i18n.t('audiences:rules.time.days')}`;
  if (timeCondition.includes('w')) return `${numberValue} ${i18n.t('audiences:rules.time.weeks')}`;
  if (timeCondition.includes('m')) return `${numberValue} ${i18n.t('audiences:rules.time.minutes')}`;
  if (timeCondition.includes('h')) return `${numberValue} ${i18n.t('audiences:rules.time.hours')}`;
  return timeCondition;
};

const TheadCell = ({ children, className = 'u-size3of10' }) => (
  <div className={cx('Table-cell Table-cell--header', className)}>{children}</div>
);

const defaultTs = {
  property: 'Property',
  variables: 'Variables',
  constraint: 'Constraint',
  filter: 'Filter Value',
  unique: 'Unique Values',
  storedVariable: 'Store in variables',
  configuredVariable: 'Configured variable',
  inversed: 'NOT',
};

const RuleCardView = ({
  properties = [],
  typeLabel,
  title,
  type,
  timeCondition,
  waitTime = null,
  isInversed = false,
  className = null,
  testHook = 'ruleCard',
  ts = defaultTs,
  visibleColumns = defaultVisibleProperties,
  isAudienceRule = false,
  ruleDefinitionId = null,
  criteria = [],
  filters = [],
  // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
  const isDynamicTimeRule = type === RulesTypes.DynamicWaitTime;

  const filteredVisibleColumns = isDynamicTimeRule
    ? visibleColumns.filter(col => !['unique', 'storedVariable'].includes(col))
    : visibleColumns;

  const icon = getIcon(type);
  const isFixedTimeRule = type === RulesTypes.FixedTime;
  const timeToDisplay = getTimeCondition(isFixedTimeRule ? waitTime : timeCondition);
  const cellSizeClassName = cellSizeClassNames[filteredVisibleColumns.length];
  const hasDateRuleConstraint =
    isAudienceRule && properties?.length > 1 && properties?.some(filter => filter?.constraintGroup === 'DATE');

  const hasDuplicateRuleWithDateConstraint =
    isAudienceRule && checkDuplicateEventWithDateProperties(isAudienceRule, criteria, type, title, filters);
  return (
    <div
      className={cx(
        't-ruleCardView relative mb-6 flex w-full flex-col rounded-md border border-gray-200 bg-white',
        className,
        `t-${testHook}`,
        {
          'border-red-300': isInversed,
        },
      )}
    >
      <div className="w-full">
        <div className="t-ruleCardViewNameIcon flex items-center gap-2 p-2">
          {icon}
          <div className="flex-1">
            {title && (
              <span className="t-ruleCardViewTitle flex items-center gap-1 py-0.5 text-xl font-medium text-gray-800">
                {type === RulesTypes.ReusableAudience ? (
                  <Link testHook="goToAudienceLink" href={buildUrl(`audiences/segments/view/${ruleDefinitionId}`)}>
                    {title}
                  </Link>
                ) : (
                  <div>{title}</div>
                )}
                <Icons
                  icon="info"
                  className="h-6 w-5 p-0.5 text-gray-400"
                  tooltip={i18n.t(`audiences:rules.descriptions.${type}`)}
                />
              </span>
            )}
            <div
              className={cx('flex items-center gap-1 py-0.5 text-sm text-gray-500', {
                ' text-xl font-medium text-gray-800': !title,
              })}
            >
              {typeLabel}
              {!title && (
                <Icons
                  icon="info"
                  className="h-6 w-5 p-0.5 text-gray-400"
                  tooltip={i18n.t(`audiences:rules.descriptions.${type}`)}
                />
              )}
            </div>
            {type === RulesTypes.FixedTime && <div className="RuleCardView-properties">{timeToDisplay}</div>}
          </div>
        </div>

        <div className={cx('RuleCardView-properties', { 'p-4': properties && properties.length > 0 })}>
          {properties && properties.length > 0 && (
            <Table stateKey="testKey" className="RuleCardView-table" testHook="ruleCartPropertiesTable">
              <div className="Table-row RuleCardView-table-row">
                {filteredVisibleColumns.includes('property') && (
                  <TheadCell className={cellSizeClassName}>{isDynamicTimeRule ? ts.variables : ts.property}</TheadCell>
                )}
                {filteredVisibleColumns.includes('constraint') && (
                  <TheadCell className={cellSizeClassName}>{ts.constraint}</TheadCell>
                )}
                {filteredVisibleColumns.includes('filter') && (
                  <TheadCell className={cellSizeClassName}>{ts.filter}</TheadCell>
                )}
              </div>
              <ShowMore
                list={properties}
                renderItem={(prop, index) => (
                  <TableRowProperty
                    {...prop}
                    key={index}
                    visibleColumns={filteredVisibleColumns}
                    hasDateRuleConstraint={hasDateRuleConstraint || hasDuplicateRuleWithDateConstraint}
                  />
                )}
                btnClassName="u-marginLeftS"
              />
              {hasDateRuleConstraint && <CardMessage message={i18n.t('audiences:edit.dateRuleConstraint')} />}
            </Table>
          )}
          {hasDuplicateRuleWithDateConstraint && <CardMessage message={i18n.t('audiences:duplicateDateRuleError')} />}
        </div>
      </div>
    </div>
  );
};

RuleCardView.propTypes = {
  typeLabel: PropTypes.string,
  title: PropTypes.string,
  iconBackgroundColor: PropTypes.string,
  isInversed: PropTypes.bool,
  timeCondition: PropTypes.string,
  className: PropTypes.string,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  properties: PropTypes.arrayOf(
    PropTypes.shape({
      property: PropTypes.string,
      constraint: PropTypes.string,
      filter: PropTypes.string,
      unique: PropTypes.number,
      storedVariable: PropTypes.string,
    }),
  ),
  testHook: PropTypes.string,
  ts: PropTypes.shape({
    property: PropTypes.string,
    constraint: PropTypes.string,
    filter: PropTypes.string,
    unique: PropTypes.string,
    inversed: PropTypes.string,
  }),
  isAudienceRule: PropTypes.bool,
};

export default RuleCardView;
