import { get, merge, set, uniq, toUpper } from 'lodash';
import i18n from '~/i18n';
import { hasSubMenu, RulesMap } from '~/common/modules/rules/selectors';
import { RulesTypes } from '~/common/modules/rules/types';
/**
 * Need merge audience with active partners because we have 2 different api for connector variables
 */
export const mergeAudienceWithActivePartners =
  (activePartners = []) =>
  (audience = {}) => {
    const integrations = audience.segment.integrations.map(integration => {
      const activePartner = activePartners.find(partner => partner.partnerId === integration.partnerDetails.partnerId);
      const activePartnersVariables = get(activePartner, 'configuration.variables');

      if (!activePartnersVariables) return integration;

      const pathToIntegrationVariables = 'partnerDetails.configuration.variables';

      const integrationVariables = get(integration, pathToIntegrationVariables, {});

      const mergedVariables = merge(integrationVariables, activePartnersVariables);
      return set(integration, pathToIntegrationVariables, mergedVariables);
    });

    return set(audience, 'segment.integrations', integrations);
  };

export const addNamesFromRuleDefinitions =
  (ruleDefinitions = []) =>
  (criteria = []) => {
    const rulesCombinations = criteria.map(ruleSet => ({
      rules: ruleSet.rules?.map(rule => {
        const ruleDefinition =
          ruleDefinitions.find(definition => definition.ruleDefinitionId === rule.ruleDefinitionId) || {};

        /*
        For rule types with subitems (like Engagement or Reusable Audience)
        ruleName is a name of subitem. Examples: "Search", "Frequent travelers"

        For rule types without subitems (like Visit or Trigger Timer)
        ruleName is a name of rule type. Examples: "Visit", "Trigger Timer"
      */

        return {
          ...rule,
          ruleName: ruleDefinition.ruleName,
        };
      }),
    }));

    return rulesCombinations;
  };

// Overlap rules are special - their names are stored in trigger's "criteria" object,
// and not in "ruleDefinitions" like for all other rule types.
export const addNamesToJourneyOverlapRules = criteria =>
  criteria.map(ruleSet => ({
    rules: ruleSet.rules?.map(rule => {
      if (rule.clazz === RulesTypes.JourneyOverlap) {
        return {
          ...rule,
          ruleName: rule.dependantJourneyName,
        };
      }

      return rule;
    }),
  }));

export const addNamesFromRuleDefinitionsToPath =
  (ruleDefinitions = [], pathToCriteria) =>
  (audienceOrTrigger = {}) => {
    const criteria = get(audienceOrTrigger, pathToCriteria, []);

    const audienceOrTriggerWithNames = addNamesFromRuleDefinitions(ruleDefinitions)(criteria);

    return set(audienceOrTrigger, pathToCriteria, audienceOrTriggerWithNames);
  };

export const addVariablesToCriteria =
  (variables = []) =>
  (criteria = []) => {
    const rulesCombinations = criteria.map(combination => ({
      rules: combination.rules?.map(rule => {
        const filters = rule.filters?.map(filter => {
          const findedVariable = variables.find(variable => variable.variableId === filter.profileVarId);
          const storedVariable = findedVariable
            ? { ...findedVariable, type: toUpper(findedVariable?.variableType?.label || '') }
            : null;

          return Object.assign({}, filter, { storedVariable });
        });

        return Object.assign({}, rule, { filters });
      }),
    }));

    return rulesCombinations;
  };

export const mergeAudienceWithVariables =
  (variables = []) =>
  (audience = {}) => {
    const criteria = get(audience, 'segment.criteria', []);
    const audienceWithVariables = addVariablesToCriteria(variables)(criteria);

    return set(audience, 'segment.criteria', audienceWithVariables);
  };

// Removes unnecessary nesting - gets rid of "rules" property from ruleSet
export const flattenCriteria = criteria => criteria.map(ruleSet => ruleSet.rules);

const getTimeValue = (timeFrame, time) => {
  if (timeFrame === 'FUTURE') return i18n.t('audiences:rules.future', { time });
  if (timeFrame === 'PAST') return i18n.t('audiences:rules.past', { time });
  return '';
};

const getDateWithinValue = (timeFrame, filterValue) => {
  const time = `${filterValue.rangeFrom} to ${filterValue.rangeTo}`;
  if (timeFrame === 'FUTURE') return i18n.t('audiences:rules.future', { time });
  if (timeFrame === 'PAST') return i18n.t('audiences:rules.past', { time });
  return '';
};

const getFilter = filterValue => {
  const filterMap = {
    ExperimentFilterValue: () => filterValue.name,
    DateFilterValue: () => `${getTimeValue(filterValue.timeFrame, filterValue.value)}`,
    DateWithinFilterValue: () => `${getDateWithinValue(filterValue.timeFrame, filterValue)}`,
    NumberWithinFilterValue: () => `${filterValue.rangeFrom} to ${filterValue.rangeTo}`,
    TimeFrameWithinFilterValue: () => `${filterValue.rangeFrom} to ${filterValue.rangeTo}`,
    default: () => filterValue.value,
  };

  const getFilterVisibleName = filterMap[filterValue.clazz] || filterMap.default;
  return getFilterVisibleName();
};

export const addPropertiesToCriteria = criteria =>
  criteria.map(ruleSet =>
    ruleSet.map(rule => {
      const properties = rule.filters?.map(filter => ({
        property: filter.dataField,
        constraint: get(filter, 'constraint.matchType.label'),
        constraintGroup: filter.constraint.group.name,
        unique: filter.minListSize,
        filter: getFilter(filter.filterValue),
        storedVariable: filter.storedVariable,
      }));
      return Object.assign({}, rule, {
        properties,
        title: rule?.ruleName === rule?.ruleType?.label ? '' : rule.ruleName,
        type: rule?.clazz,
        id: rule?.ruleId,
        groupName: rule?.ruleCategory?.label,
        ...rule,
      });
    }),
  );

export const getTitleAndTypeLabel = (type, ruleName) => {
  let title;
  let typeLabel;

  if (hasSubMenu(type)) {
    title = ruleName;
    typeLabel = RulesMap[type].title;
  } else {
    // eslint-disable-next-line prefer-destructuring
    title = RulesMap[type]?.title;
    typeLabel = '';
  }

  return { title, typeLabel };
};

export const addTitleAndTypeLabel = criteria =>
  criteria.map(ruleSet =>
    ruleSet.map(rule => {
      const { title, typeLabel } = getTitleAndTypeLabel(rule.type, rule.ruleName);

      return {
        ...rule,
        title,
        typeLabel,
      };
    }),
  );

export const getVariablesUsedInRules = criteria => {
  const allFilters = criteria
    .flat()
    .flatMap(({ filters }) => filters)
    .filter(filterValues => typeof filterValues !== 'undefined');

  const variables = allFilters
    .filter(({ profileVarId }) => typeof profileVarId !== 'undefined' && profileVarId !== null)
    .map(({ profileVarId }) => profileVarId);

  return uniq(variables);
};
